.headerdesktoplogo {
    height: 15vh;
    width: 10vw;
}

.btn-primary {
    background-color: #b31b34 !important;
    outline: none !important;
    outline: none !important;
    border-color: #b31b34 !important;
    outline-color: #b31b34 !important;
    box-shadow: none !important;
    font-weight: bold !important;
}

.form-control, .form-control:focus, .form-control:active {
    border: 0px transparent !important;
    border-radius: 0% !important;
    border-bottom: 1px solid black !important;
    outline: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
}

.submit-btm-div {
    display: flex !important;
}

.footer {
    background-color: white !important;
    position: fixed !important;
    left: 0 !important;
    bottom: 0 !important
}

.submit-btn {
    /* align-items: center !important;
    justify-content: center !important; */
    margin:auto !important;
    display:block !important;
}

@media (max-width: 1024px) and (min-width: 770px) {
    .headerdesktoplogo {
        height: 15vh !important;
        width: 20vw !important;
    }

    .user-forms {
        max-height: 500px !important;
        overflow-y: scroll !important;
    }
}

@media (max-width: 769px) and (min-width: 415px) {

    .headerdesktoplogo {
        height: 15vh !important;
        width: 20vw !important;
    }
    
}

@media (max-width: 414px) and (min-width: 390px) {
    .headermobilelogo {
        height: 12vh !important;
        width: 23vw !important;
    }
    .body-render-container {
        max-height: 80vh !important;
        overflow-y: auto !important;
        touch-action: auto;
        height: 100% !important;
    }

    .footer {
        position: relative !important;
    }

    .user-forms {
        max-height: 500px;
        overflow-y: auto;
    }
}

@media (max-width: 389px) {
    .headermobilelogo {
        height: 12vh !important;
        width: 23vw !important;
    }

    .body-render-container {
        max-height: 70vh !important;
        max-width: 100% !important;
        overflow-x: auto !important;
        overflow-y: auto !important;
        touch-action: auto;
        height: 100% !important;
    }

    .footer {
        position: relative !important;
    }

    .user-forms {
        overflow-y: auto !important;
        overflow-x: auto !important;
        touch-action: auto;
    }
}

#amountToPay {
    overflow-x: auto !important;
}